.container {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
  width: 100%;
  min-height: 400px;
  margin-top: 50px;
  padding: 50px 0;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 340px;
  min-height: 480px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(18, 26, 46, 0.1);
  transition: box-shadow 200ms ease-in-out;

  &:hover {
    box-shadow: 0px 4px 8px rgba(18, 26, 46, 0.18);
  }
}

.isActive {
  @extend .card;
  background-color: #dbedec;
}

.dataContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 30px 10px;

  .cancelled {
    color: #dc3545;
  }

  h3 {
    color: #319b93;
    font-size: 1.8rem;
    margin-bottom: 18px;
  }

  p {
    margin-bottom: 12px;
  }

  .price {
    font-size: 2.2rem;
  }
}

.callToAction {
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dbedec;
  border-radius: 0 0 10px 10px;
  font-size: 1.1rem;

  svg {
    margin-left: 5px;
    height: 1.4rem;
  }

  &:hover {
    cursor: pointer;
  }
}

.disabled {
  @extend .callToAction;
  background-color: #319b93;
  color: #fff;

  &:hover {
    pointer-events: none;
  }
}

.inCancellation {
  @extend .callToAction;
  filter: saturate(20%);

  &:hover {
    pointer-events: none;
  }
}

.learnMore {
  color: #319b93;
  text-decoration: none;
  transition: all 200ms ease-in-out;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.subscriptionModal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
}

.subscriptionModalCard {
  width: 30vw;
  min-height: 20h;
  padding: 40px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
}

.subscriptionButtonContainer {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.confirm {
  background-color: #dbedec;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 200ms ease-in-out;

  &:hover {
    opacity: 1;
  }
}

.cancel {
  @extend .confirm;
  background-color: #dc3545;
  color: #fff;
}
