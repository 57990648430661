@keyframes outline {
  0% {
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dashoffset: -260;
  }
  100% {
    stroke-dashoffset: -520;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  display: flex;
  justify-content: center;
  animation: spin linear 1.2s infinite;

  svg {
    width: 100%;
    max-width: 10rem;
  }

  circle {
    stroke-dasharray: 260;
    animation: outline 2s cubic-bezier(0.77, 0, 0.18, 1) infinite;
  }
}
