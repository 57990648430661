.listContainer {
  margin-top: 30px;
}

.list {
  li {
    margin-bottom: 5px;
  }
}

.callToAction {
  width: 200px;
  margin-top: 50px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #66b031;
  border-radius: 4px;
  color: white !important;
}

.baseCopy {
  margin-top: 50px;
}
