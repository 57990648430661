.buttonContainer {
  width: 400px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  button {
    background-color: #dbedec;
    border: none;
    border-radius: 4px;
    padding: 10px 14px;
    margin-right: 50px;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 200ms ease-in-out;
    color: #000;

    &:hover {
      opacity: 1;
    }
  }

  .cancel {
    background-color: #dc3545;
    color: #fff;
  }
}

.hr {
  width: 330px;
  height: 1px;
  background-color: #c0c0c0;
  border: none;
  margin-left: 0;
}

.footerContainer {
  padding-top: 20px;

  h3 {
    margin-bottom: 20px;
  }
}

.detailsContainer {
  display: flex;
  align-items: center;
  column-gap: 20px;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.detailsHeader {
  font-weight: bold;
  width: 150px;
}

.detailsContent {
  display: block;
  &::first-letter {
    text-transform: capitalize;
  }
}

.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);

  .modalCard {
    width: 30vw;
    min-height: 20h;
    padding: 40px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;

    .buttonContainer {
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      button {
        background-color: #dbedec;
        border: none;
        border-radius: 4px;
        padding: 10px 20px;
        cursor: pointer;
        opacity: 0.8;
        transition: opacity 200ms ease-in-out;

        &:hover {
          opacity: 1;
        }
      }

      .cancel {
        background-color: #dc3545;
        color: #fff;
      }
    }
  }
}
