@font-face {
  font-family: muli-Light;
  src: url('./assets/fonts/Muli-Light.ttf');
  font-display: swap;
}

@font-face {
  font-family: muli-Regular;
  src: url('./assets/fonts/Muli-Regular.ttf');
  font-display: swap;
}

.font-MuliReg {
  font-family: muli-Regular;
}

@font-face {
  font-family: muli-bold;
  src: url('./assets/fonts/Muli-Bold.ttf');
  font-display: swap;
}

.font-MuliBold {
  font-family: muli-bold;
}

@font-face {
  font-family: Quicksand-Regular;
  src: url('./assets/fonts/Quicksand-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: Quicksand-bold;
  src: url('./assets/fonts/Quicksand-Bold.ttf');
  font-display: swap;
}

/* ************ */
@font-face {
  font-family: SourceSansPro-Regular;
  src: url('./assets/fonts/SourceSansPro-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: SourceSansPro-Bold;
  src: url('./assets/fonts/SourceSansPro-Bold.ttf');
  font-display: swap;
}

body {
  margin: 0px;
}

a {
  font-family: muli-Regular;
  color: #4da59e !important;
}

p {
  font-family: muli-Regular;
}

.underline {
  text-decoration: underline;
}

.hyperlink {
  cursor: pointer !important;
}

.font-muli-Regular {
  font-family: muli-Regular;
}

.wrap_spacer_base {
  margin-top: 1.125rem;
}

.wrap_spacer_small {
  margin-top: 2.25rem;
}

.wrap-spacer-medium {
  margin-top: 3.25rem;
  margin-bottom: 1.25rem;
}

.wrap-spacer-large {
  margin-top: 3.75rem;
  margin-bottom: 2.5rem;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.info-container,
.info-containers {
  display: flex;
  justify-content: flex-end !important;
}

.leftBarSliders {
  position: fixed;
  width: 100%;
  height: 100%;
  right: 100%;
  transition: 0.5s;
  z-index: 999;
}

.leftBarSliders.active {
  right: 0px;
}

.showNavContainer {
  position: fixed;
  z-index: 999;
  width: 100%;

  background: #f7f7f7;
  padding: 10px;
}

.showNavContainer div {
  cursor: pointer;
  width: 40px;
  height: 40px;
  /* background: black; */
  color: black;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.closeSideBar {
  cursor: pointer;
  width: 40px;
  height: 40px;
  background: black;
  color: white;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: 10px;
}

/* .leftBarSlider {
  width: 500px;
} */

.intro-left-contaimer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.left-img-container {
  min-height: 100vh;
  background-color: #cccccc;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0.675rem 2rem;
  position: relative;
  /* padding-bottom: 221px; */
}

.backArrow {
  background: #1f202b;
  border-radius: 50%;
  color: white;
  width: 35px;
  height: 35px;
  font-size: 20px;
  cursor: pointer;
  margin: 30px 20px 10px 40px;
}

.signUp-progressBar,
.agree-terms-wrap > div,
.epop-dashboard-circle,
.agree-terms-wrap,
.plan-price,
.right-img-list .img,
.right-img-list,
.customList-checkbox,
.des-info-container .des-info-list,
.left-img-container .info-container,
.backArrow,
.imgAlignCenter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.elem {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.elem {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.elem {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.epop-dashboard-circle,
.agree-terms-wrap > div,
.agree-terms-wrap,
.plan-price,
.right-img-list .img,
.customList-checkbox,
.left-img-container .info-container,
.backArrow,
.imgAlignCenter,
.elem {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.elem {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.epop-dashboard-circle,
.plan-price,
.right-img-list .img,
.backArrow,
.imgAlignCenter,
.elem {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.elem {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.left-img-container .info-container,
.elem {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: space-between;
}

.agree-terms-wrap,
.customList-checkbox,
.elem {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.right-img-list,
.elem {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.epop-dashboard-circle,
.signUp-progressBar,
.plan-price,
.elem {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.elem {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.elem {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.elem {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.elem {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.elem {
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.elem {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

button:hover,
button:focus,
button:active {
  outline: none;
}

.main-heading {
  font-weight: bold;
  font-family: muli-bold;
  color: #319b93 !important;
}

.store-name-heading {
  color: #c5c5c5 !important;
  font-family: muli-bold;
  margin-top: 0px !important;
}

.store-container {
  padding: 20px;
  border-radius: 6px;
  background: #f0f0f0;
  -webkit-box-shadow: 0 3px 6px #00000029;
  box-shadow: 0 3px 6px #00000029;
}

.store-details-wrap {
  background: white;
  border-radius: 8px;
  padding: 10px;
}

.store-field-details {
  font-family: SourceSansPro-Regular;
  font-weight: bold;
  min-height: 30px;
  padding-top: 0.375rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.gray-submit,
.success-submit {
  font-family: SourceSansPro-Regular;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  line-height: 1.5;
  font-size: 1rem;
  cursor: pointer;
}

.success-submit {
  background-color: #8dc63f !important;
  color: #fff !important;
}

.success-submit:disabled,
.gray-submit {
  background-color: #666666 !important;
  color: #fff !important;
}

/*, .success-submit-btn, .gray-submit-btn*/

.ant-btn {
  font-family: SourceSansPro-Regular;
  border-radius: 0.25rem !important;
  letter-spacing: 0.5px !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  color: #4da59e;
  margin-bottom: 0 !important;
}

.ant-btn.success-submit-btn {
  background-color: #8dc63f !important;
  color: white !important;
}

.ant-btn.gray-submit-btn {
  background-color: #666666 !important;
  color: white !important;
}

.ant-btn-link {
  color: #666666 !important;
  background: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ant-btn.ant-btn-link:hover:not([disabled]),
.ant-btn.ant-btn-link:focus:not([disabled]),
.ant-btn.ant-btn-link:active:not([disabled]) {
  background: transparent !important;
  border-color: transparent !important;
  color: #4da59e !important;
}

.ant-btn:hover:not([disabled]),
.ant-btn:focus:not([disabled]),
.ant-btn:active:not([disabled]) {
  background-color: #4da59e !important;
  border-color: #4da59e !important;
  color: #fff !important;
}

.ant-btn.ant-btn-lg {
  height: 45px;
  padding: 0.5rem 2rem;
}

.ant-btn.ant-btn-sm {
  height: 35px;
  padding: 0.5rem 2rem;
}

.ant-btn.planSelectbtn {
  background: #c5c5c5 !important;
  font-family: muli-bold;
  font-weight: bolder;
  border-radius: 0;
  letter-spacing: 0.25px !important;
  color: #333 !important;
  font-size: 0.875rem;
  height: 35px;
  width: 100%;
  padding: 0px !important;
}

.ant-btn.planSelectbtn.active {
  background: #4da59e !important;
  color: #fff !important;
}

.ant-input[disabled] {
  color: #000000 !important;
  border: 1px solid #4da59e !important;
}

.pos-input > input.ant-input {
  margin-top: 0px !important;
}

/* .ant-btn.planSelectbtn.active:hover,
.ant-btn.planSelectbtn.active:focus,
.ant-btn.planSelectbtn.active:active {
} */

/* Custom Checkboxes*/
/* .custom-checkbox {
} */

.custom-checkbox .ant-checkbox-wrapper {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  /* justify-content: space-between; */
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.custom-checkbox .ant-checkbox-wrapper .ant-checkbox-inner {
  width: 25px;
  height: 25px;
}

.custom-checkbox .ant-checkbox-wrapper .ant-checkbox-inner,
.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  border: 2px solid #4da59e;
}

.custom-checkbox .ant-checkbox-checked::after,
.custom-checkbox .ant-checkbox:hover .ant-checkbox-inner,
.custom-checkbox .ant-checkbox:focus .ant-checkbox-inner,
.custom-checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.custom-checkbox .ant-checkbox-wrapper:focus .ant-checkbox-inner,
.custom-checkbox .ant-checkbox-input:hover + .ant-checkbox-inner,
.custom-checkbox .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #4da59e;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #666;
  border-width: 3px;
  height: 0.875rem;
  width: 0.5rem;
  left: 25%;
}

.custom-checkbox .ant-checkbox-wrapper span:last-child {
  /*font-size: 0.75rem;*/
  align-items: flex-end;
  margin-right: auto;
}

/**/

/*.left-img-container .logo-container {*/
/*    float: right;*/
/*    margin-top: 20px;*/
/*}*/

.left-img-container .logo-container img.square-logo {
  height: 35px !important;
  width: 35px !important;
  margin-right: 10px;
}

.left-img-container .logo-container img {
  height: 80px;
  width: 80px;
}

.left-img-container .des-container {
  margin: 1.875rem 1.25rem 0 0;
}

.left-img-container .des-container > h2 {
  font-family: muli-Regular;
  text-align: right;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 30px;
  font-weight: 100;
  line-height: 32px;
  opacity: 1;
}

.left-img-container .des-container > h2 span {
  font-family: Quicksand-Regular;
  font-size: 25px;
  margin-top: 10px;
  display: block;
}

.merchant-name-heading > h2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 24px !important;
}

.agree-terms-wrap,
.left-img-container .info-container {
  margin: 1.25rem 0px;
}

.des-info-container {
  margin-right: 1.25rem;
}

.des-info-container .des-info-list {
  margin-top: 24px;
}

.des-info-container .des-info-list img {
  height: 25px;
}

.des-info-container .des-info-list p,
.des-info-container .des-info-lists p {
  font-family: Quicksand-Regular;
  margin-left: 10px;
  line-height: 25px;
  font-weight: 600;
  font-size: 18px;
  font-weight: normal;
  cursor: pointer;
  color: #000000;
}

.Right-wrapper {
  margin: 50px auto 50px;
  background: #f7f7f7;
  /* box-shadow: 0px 3px 6px #00000029; */
  border-radius: 5px;
  min-height: 80vh;
  padding: 20px;
}

.right-header {
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 0;
}

.form-label-header {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0;
}

/* **************** */
.addNewStore {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.addNewStore svg {
  font-size: 20px;
  color: white;
  background: black;
  border-radius: 50%;
}

/* **************** */
.merchant-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.right-header p {
  font-size: 14px !important;
  /* font-weight: 600; */
  font-family: muli-Regular;
}

.right-header h1 {
  font-size: 24px !important;
}

.right-header h2 {
  font-size: 20px !important;
  font-family: muli-bold;
}

.customList-checkbox {
  border: 1px solid #c5c5c5;
  background: white;
  border-radius: 7px;
  margin-top: 20px;
  cursor: pointer;
  padding: 14px;
}

.customList-checkbox.active {
  background: #dbedec;
  border: 1px solid #4da59e;
}

.customList-checkbox p {
  margin-bottom: 0;
  color: #1f202b;
  font-family: SourceSansPro-Regular;
}

.customList-checkbox .checked {
  color: #319b93;
  font-size: 25px;
}

.customList-checkbox .unchecked {
  color: #c5c5c5 !important;
  font-size: 25px;
}

.customList-checkbox span {
  font-family: SourceSansPro-Bold;
}

/* ************** */
.fixedHight {
  height: 100vh;
  overflow: scroll;
}

/* ************** */

.left-img-wrap {
  display: flex;
  flex: 3;
  justify-content: flex-end;
  align-items: flex-end;
}

/* ************** */

.left-img-container-1 {
  margin: 0.675rem 1.25rem 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.left-img-wrap img {
  height: 165px;
}

/* *********************** */
.store-list-circle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  font-family: SourceSansPro-Bold;
}

.store-list-circle > div span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.store-list-circle > div p {
  margin-bottom: 0px;
  padding: 0px 3px;
  font-family: Quicksand-bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.store-list-circle > div {
  padding: 0.75rem;
  width: 160px;
  height: 160px;
  margin: 0.75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  border: 5px solid #319b93;
}

.store-list-circle > div:active {
  background: #dbedec;
}

/* ********** */
.store-list-circle-plan {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: SourceSansPro-Bold;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.store-list-circle-plan p {
  margin-bottom: 0px;
}

.store-list-circle-plan > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  width: 110px;
  height: 110px;
  padding: 5px;
  margin: 0;
  border: 5px solid #319b93;
  background: #fff;
}

/* *********************** */

.right-img-list {
  /* margin: 0px 20px; */
  text-align: center;
}

.right-img-list .img {
  min-height: 100px;
}

.right-img-list p {
  font-family: SourceSansPro-Regular;
  line-height: 1rem;
  padding: 0 0.25rem;
}

.image-upload-container {
  position: relative;
  background: #c5c5c5;
  border-radius: 0.675rem;
  width: 100px;
  height: 100px;
  margin: 20px;
}

.image-preview-container {
  position: relative;
  background: #c5c5c5;
  border-radius: 0.675rem;
  width: 100px;
  height: 100px;
  margin: 0px;
}

.image-upload-container img.logo-upload {
  display: block;
  position: absolute;
  bottom: -15px;
  right: -43px;
  font-size: 27px;
  cursor: pointer;
  height: 40px;
}

/* ************************ */
.planListWrap {
  padding-top: 0.25rem;
  border: 4px solid transparent;
  cursor: pointer;
}

.planListWrap .Plan-list-container {
  position: relative;
  margin: 0 0.25rem;
  min-height: 210px;
  /*height: 260px;*/
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.planListWrap .Plan-list-container .plan-list-wrap {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
}

.price-perMonth {
  font-size: 30px;
  margin-top: 6px;
  margin-left: 6px;
  margin-right: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.price-perMonth-space {
  font-size: 30px;
  margin-top: 6px;
  margin-left: 36px;
  margin-right: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.price-perMonth .span {
  font-size: 12px;
  padding: 4px 1px;
}

.price-perMonth sup {
  top: -0.25em !important;
}

.price-perMonth sub {
  bottom: -0.5em !important;
}

.planListWrap .Plan-list-container .plan-list-wrap .plan-price {
  border: 4px solid #1f202b;
  font-size: 14px;
  height: 100%;
  min-height: 99px;
}

.plan-price .plan-price-type {
  font-size: 15px;
  font-weight: bold;
}

.edit-store-btn {
  position: absolute !important;
  right: 20px;
  top: 20px;
}

.store-field-labels {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  font-family: Quicksand-Regular;
  color: #1f202b;
  padding-right: 1rem;
  padding-top: 0.5rem;
}

.Plan-list-container .free-des {
  background: #7bbf44;
  justify-content: center;
  padding: 10px;
  min-height: 60px;
  color: white;
  display: flex;
  align-items: center;
}

.Plan-list-container .free-des.bg-transparent {
  background: transparent;
}

.Plan-list-container .free-des p {
  font-family: muli-bold;
  margin-bottom: 0px;
}

.Plan-list-container .free-des.transparent {
  background: transparent;
}

/* ************************ */
.Plan-content-row {
  text-align: center;
  font-family: muli-bold;
  border: 4px solid #4da59e;
}

.Plan-content-row .checked {
  color: #7bbf44;
  height: 35px;
}

/* Merchant Settings */
.image-drop-down input[type='text'] {
  margin-top: 0px !important;
}

/* ************************ */
.login-Form input[type='email'],
input[type='password'],
input[type='text'],
select {
  font-family: SourceSansPro-Regular;
  margin-top: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: block;
  width: 100%;
  height: calc(2em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  border-radius: 0.25rem;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid transparent !important;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.login-Form input:focus,
.login-Form input:focus,
.login-Form input:focus {
  border: 2px solid #4da59e !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.login-Form .fieldContainer1 select {
  height: calc(2em + 0.75rem + 2px);
  border: 0px;
  border-radius: 4px;
}

.terms-conditions {
  font-family: SourceSansPro-Regular;
  margin-bottom: 0;
}

.terms-conditions a,
.epop-link {
  text-decoration: none;
  font-family: SourceSansPro-Regular !important;
  color: #4da59e;
}

.terms-conditions a:hover,
.epop-link:hover {
  text-decoration: none;
  color: #4da59e;
}

.fieldContainer {
  position: relative;
}

.icon-bag img {
  width: 25px;
  height: 25px;
  margin-right: 8px;
  cursor: pointer;
}

.antdDropDown > div {
  background: white;
  height: 37px !important;
  padding: 10px;
  padding: 4px 15px !important;
  color: black !important;
  border-radius: 3px;
  border: none !important;
}

.antdDropDown .ant-select-arrow {
  background: #c5c5c5 !important;
  width: 33px;
  height: 37.2px;
  right: -5px;
  top: 6px;
  padding-top: 14px;
  color: white;
  border-radius: 0px 4px 4px 0px;
}

.comming-soon {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  font-style: italic;
  margin-top: 130px;
  font-family: muli-bold;
}

.linkss {
  color: #1f202b !important;
  text-decoration: none;
  cursor: pointer !important;
  display: block;
}

.linkss:hover {
  color: #319b93 !important;
  text-decoration: none;
  cursor: pointer;
}

.eye-show {
  position: absolute;
  cursor: pointer;
  right: 15px;
  top: 15px;
  z-index: 999;
}

.login-Form input:focus,
.flagDropDown > button:focus {
  outline: none;
}

.terms-conditions-check {
  width: 100%;
}

.agree-terms-wrap > div input[type='checkbox'] {
  margin-right: 0.675rem;
}

.flagDropDown .ReactFlagsSelect {
  padding: 0 !important;
}

.flagDropDown > button {
  border: 1px solid transparent;
  background: white !important;
  margin-top: 10px;
  width: 100%;
  height: 40px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  height: 40px !important;
  background-clip: padding-box;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.right-img-list > div {
  width: 50%;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.text-danger {
  color: red;
}

.flagDropDown > button:focus {
  border: 2px solid #4da59e !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.flagDropDown > button span {
  font-family: SourceSansPro-Regular;
  margin-left: -3px;
  font-size: 1rem;
  font-weight: 400;
  color: #495057;
}

.flagDropDown:after {
  position: absolute;
  content: '';
  width: 41px;
  height: 40px;
  display: block;
  margin-left: 5px;
  background: #4da59e;
  right: 0;
  top: 0px;
  z-index: 1;
  border-radius: 0 5px 5px 0;
}

/* ************** */
.image-drop-down.borders {
  border: none !important;
}

.image-drop-down.borders {
  border: 2px solid #4da59e !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.image-drop-down .menu {
  margin: 5px 0px !important;
}

.image-drop-down i::before {
  display: none !important;
}

.image-drop-down i {
  border-top: 2px solid rgb(255, 255, 255) !important;
  position: absolute;
  margin-top: 1px !important;
  width: 10px !important;
  padding: 5px !important;
  border-left: 2px solid rgb(252, 252, 252) !important;
  transform: rotate(-135deg);
  margin-right: 2px !important;
}

.image-drop-down:after {
  position: absolute;
  content: '';
  width: 41px;
  height: 38px;
  display: block;
  margin-left: 5px;
  background: #4da59e;
  right: -1px;
  top: -1px;
  z-index: 1;
  border-radius: 0 5px 5px 0;
}

/* ************** */

.place-Holder-Op button span {
  opacity: 0.4;
}

.flagDropDown .ReactFlagsSelect-module_selectBtn__19wW7:after {
  border: none !important;
  border-top: 2px solid white !important;
  border-right: 2px solid white !important;
  padding: 4px;
  margin-right: 4px !important;
  z-index: 9;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  margin-top: -5px;
}

.flagDropDown .ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded='true']:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  z-index: 9;
  margin-top: 5px;
}

.wrapperPaddingLarge {
  padding-left: 4rem;
  padding-right: 4rem;
}

.wrapperPaddingMedium {
  padding-left: 3rem;
  padding-right: 3rem;
}

.wrapperPaddingSmall {
  padding-left: 2rem;
  padding-right: 2rem;
}

.wrapperPadding {
  padding-left: 1rem;
  padding-right: 1rem;
}

/* ************** */

/* ******** */
.plans-headings {
  color: #319b93 !important;
  font-family: muli-bold;
  letter-spacing: 0px;
  opacity: 1;
}

.Plan-content-row {
  text-align: center;
  font-family: muli-bold;
  border: 4px solid #4da59e;
}

.tableCell {
  padding: 0.5rem;
  min-height: 55px;
  background: white;
  border: 1px solid #4da59e;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.tableCell.acitve,
.tableCell.active {
  /* border: 2px solid transparent ; */
  background: #dbedec;
}

.activeplan {
  border: 4px solid #7bbf44 !important;
  border-radius: 0.5rem;
}

.tb-topt {
  border-top: 2px solid #4da59e;
}

.tb-topr {
  border-right: 2px solid #4da59e;
}

.tb-topl {
  border-left: 2px solid #4da59e;
}

.tb-topb {
  border-bottom: 2px solid #4da59e;
}

.tablePlantick.checked svg {
  color: #7bbf44;
  height: 27px;
  margin-top: -3px;
}

.tablePlantick.unchecked svg {
  height: 24px;
}

/* ******** */

.privacy_wrap {
  padding: 1rem;
}

.privacy_heading {
  font-family: muli-bold;
  font-size: 1.125rem;
  color: #1f202b;
}

.privacy_des {
  font-family: muli-Regular;
  font-size: 1rem;
  margin-bottom: 0;
}

.signUp-progressBar {
  margin-top: 40px;
  list-style: none;
}

.signUp-progressBar li {
  font-family: Quicksand-Regular;
  margin-bottom: 55px;
  position: relative;
  text-align: right;
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: normal;
}

.signUp-progressBar li:after,
.signUp-progressBar li:last-child::after {
  position: absolute;
  content: '';
  height: 45px;
  width: 2px;
  right: 20px;
  top: 33px;
}

.signUp-progressBar li:after {
  background: black;
}

.text-light-dark {
  letter-spacing: 0px;
  color: #1f202b !important;
}

.signUp-progressBar li:last-child::after {
  background: transparent;
}

ul.signUp-progressBar img.checkedImge {
  height: 27px !important;
}

ul.signUp-progressBar img.checkedImge,
ul.signUp-progressBar img.uncheckedImge {
  margin-left: 18px;
  margin-right: 8px;
  margin-top: 2px;
  height: 25px;
}

.epop-dashboard-circle-wrap .epop-dashboard-circle {
  text-align: center;
  border-radius: 50%;
  font-size: 0.75rem;
  padding: 0.75rem;
  margin: 0.5rem;
  height: 130px;
  width: 130px;
  color: #fff;
  cursor: pointer;
}

.epop-dashboard-circle-wrap .epop-dashboard-circle.green {
  background: #319b93;
}

.epop-dashboard-circle-wrap .epop-dashboard-circle.dark {
  background: #666666;
}

.epop-dashboard-circle-wrap .epop-dashboard-circle p {
  margin-bottom: 0;
  font-size: 115%;
}

.epop-dashboard-circle-wrap .epop-dashboard-circle .bold {
  font-weight: bold;
  font-size: 20px !important;
  margin-bottom: -5px !important;
}

.epop-dashboard-circle-wrap .epop-dashboard-circle .det {
  margin: 0.5rem auto;
  font-size: 13px;
}

.epop-dashboard-circle-wrap .epop-dashboard-circle svg {
  width: 18px;
  height: 18px;
}

.epop-dashboard-location {
  border-right: 2px solid #4da59e;
}

.epop-dashboard-arrow {
  width: 30px;
  height: 30px;
  color: white;
  padding: 0 2px;
  font-size: 1.3rem;
  margin: 2rem 0.8rem;
  background: #7bbf44;
  border-radius: 0.25rem;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.epop-dashboard-arrow:hover {
  background: #4da59e;
}

.epop-dashboard-impact {
  min-height: 270px;
  margin-bottom: 41px;
  background: white;
  padding: 20px;
  width: 100%;
  width: 100%;
  box-shadow: 0px 8px 20px #00000029;
  border-radius: 5px;
}

.epop-dashboard-member {
  min-height: 165px;
  margin-bottom: 20px;
  background: white;
  padding: 20px;
  width: 100%;
  width: 100%;
  box-shadow: 0px 8px 20px #00000029;
  border-radius: 5px;
}

.epop-dashboard-reciept {
  width: 40px;
  height: 24px;
  margin: 0.25rem 0.125rem;
  background: #c5c5c5;
  display: inline-block;
  box-shadow: 0px 8px 20px #00000029;
  border-radius: 4px;
}

.epop-dashboard-reciept p {
  font-size: 40px;
  line-height: 1;
  color: #fff;
  width: 40px;
  padding-top: 4px;
  position: absolute;
}

.epop-dashboard-receipt-bottom {
  margin-top: 25px;
  margin-left: 0px;
}

.epop-dashboard-bottom,
.epop-dashboard-impact p {
  text-align: center;
}

.epop-dashboard-bottom img {
  height: 100px;
  max-width: 250px;
}

.store-epop-header,
.store-epop-header1 {
  width: 120px;
  height: 120px;
  background: #fff;
  border-radius: 50%;
  border: 4px solid #319b93;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.store-epop-header .store-logo,
.store-epop-header1 .store-logo {
  width: 100%;
  border-radius: 50%;
}

.store-epop-header-sm {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 128px;
  height: 128px;
  background: #fff;
  border-radius: 50%;
  border: 4px solid #319b93;
  text-align: center;
  font-family: Quicksand-bold;
  font-size: 12px;
}

.dashbordContainerDetails {
  box-shadow: 0px 8px 20px #00000029;
  border-radius: 8px;
}

.dashborderCus {
  font-weight: bold;
  font-size: 18px;
}

.tableReponsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}
.store-epop-header1,
.store-epop-header {
  background: #ffffff !important;
  padding: 1rem 0.8rem;
}

.store-epop-header1 p,
.store-epop-header p {
  font-weight: 700;
  line-height: 1.25;
  text-align: center;
  margin-bottom: 3px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0px 3px;
  font-family: Quicksand-bold;
}

.right-header-row {
  margin-bottom: 0.75rem;
}

.store-monthly {
  /* min-height: 350px; */
  padding: 10px 30px;
  background: #fff;
}

.store-unlock {
  position: relative;
}

.store-unlock p,
.store-lock-icon {
  color: #c5c5c5;
}

.store-lock-icon {
  font-size: 1.825rem;
  /* max-width: 250px; */
}

.store-unlock p {
  font-size: 16px;
}

.merchant-icon img {
  height: 27px !important;
  width: 27px !important;
  margin-left: 1.38rem;
  right: 1px;
}

.des-info-lists {
  font-family: Quicksand-Regular !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  line-height: 1.5rem;
  font-size: 1.125rem;
  font-weight: 600;
  margin-top: 3rem;
  text-align: right;
}

.store-circle {
  border: none;
  width: 140px;
  height: 140px;
}

.store-pos-circle {
  border: none;
  width: 40px;
  height: 40px;
}

.store-terms {
  margin-top: 50px;
}

.store-btn {
  background: #4da59e;
  border: none;
  outline: none;
  width: 114px;
  height: 38px;
  color: #fff;
}

table {
  /* font-family: arial, sans-serif; */
  border-collapse: collapse;
  width: 100%;
}
/*
th {
  background: #4da59e;
  color: #fff;
}

td,
th {
  border: 1px solid #fff;
  text-align: left;
  padding: 0.5rem;
} */

/* tr:nth-child(even) {
  background-color: #ffffff;
}
tr:nth-child(odd) {
  background-color: #dbedec;
} */

/* ******* */
.transections-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 20px;
}

.transaction-table {
  font-family: muli-Regular;
  border-collapse: collapse;
  margin-bottom: 100px;
  text-align: center;
  width: 100%;
}

.transaction-table th {
  background: #4da59e !important;
  color: #fff !important;
  text-align: center !important;
}

.transaction-table td,
th {
  border: 1px solid #fff;
  text-align: center;
  padding: 0.5rem;
}

.transaction-table tr:nth-child(even) {
  background-color: #dbedec;
}
.transaction-table tr:nth-child(odd) {
  background-color: #ffffff;
}

.fieldContainer1 {
  position: relative;
  margin-top: 1rem;
}

.btnShape {
  width: 100px;
  /* height: 46px; */
  opacity: 1;
  color: #fff;
  border-radius: 4px;
}

.primary-green-btn {
  border: #4da59e !important;
  background: #4da59e 0% 0% no-repeat padding-box !important;
  color: #fff !important;
}

.cancel-btn {
  background: #666666 0% 0% no-repeat padding-box !important;
  border: #666666 !important;
}

/* Not Used */
.cancel2-btn {
  background: #7bbf44 0% 0% no-repeat padding-box;
  border: #7bbf44;
  color: #fff !important;
}

/* Not Used */
.discard-btn {
  background: #c5c5c5 0% 0% no-repeat padding-box;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
  text-align: center;
  width: 140px;
  border: 0;
  color: #fff !important;
}

/* Not Used */
.edit-btn {
  background: #8dc63f 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 74px;
  height: 38px;
  opacity: 1;
  color: #fff;
  border: #7bbf44;
  float: right;
  color: #fff !important;
}

/* Not used */
.save-btn {
  background: #7bbf44 0% 0% no-repeat padding-box;
  border: #7bbf44;
  color: #fff !important;
}

/* Not used */
.lastvalues {
  margin-top: 16px;
  float: left;
}

.right-header-row {
  margin-bottom: 13px;
}

.progressbar-wrap {
  padding-right: 3rem;
}

/* DESKTOP FIRST MEDIA QUERIES */

/* Extra Large devices (desktops, less than 1400px)*/
@media (max-width: 1399.98px) {
}

/* Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
  .planListWrap .Plan-list-container .plan-list-wrap {
    height: auto;
  }

  .Plan-list-container .free-des {
    min-height: 60px;
  }

  .plan-price .plan-price-type {
    font-size: 14px;
  }

  .planListWrap .Plan-list-container {
    min-height: 215px;
    height: unset;
  }

  .planListWrap .Plan-list-container .planSelectbtn {
    font-weight: bold;
    font-size: 0.75rem;
    height: 35px;
  }

  .price-perMonth {
    font-size: 25px;
    margin-top: 1rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .price-perMonth .span {
    font-size: 12px;
  }

  .epop-dashboard-circle-wrap .epop-dashboard-circle {
    padding: 0.5rem;
    margin-bottom: 1rem;
  }
}

/* Custom Media Query (desktops, less than 1024px)*/
@media (max-width: 1023.98px) {
  .Plan-list-container .free-des {
    min-height: 140px;
  }
}

/* Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
  .pos-type .store-circle {
    width: 100px;
    height: 100px;
  }

  .store-terms {
    margin-top: 50px;
  }

  .planListWrap .Plan-list-container {
    min-height: 200px;
  }

  .Plan-list-container .free-des {
    min-height: 0px;
  }

  .epop-modal .ant-modal-content {
    width: 500px;
  }
}

/* Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  .Right-wrapper {
    margin: 0;
  }

  .right-header {
    padding: 0;
  }

  .adjustOffset {
    margin-left: 0 !important;
  }

  .progressbar-wrap,
  .agree-terms-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  /*.progressbar-wrap, */
  .agree-terms-wrap {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .progressbar-wrap,
  .agree-terms-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .left-img-container .logo-container img {
    height: 65px;
    width: 65px;
  }

  .des-info-container {
    margin-top: 1rem;
    /* height: 255px; */
    overflow-y: scroll;
    -ms-overflow-style: none; /* Hide scrollbar for IE, Edge and Firefox */
    scrollbar-width: none;
  }

  .des-info-container::-webkit-scrollbar {
    display: none;
  }

  .des-info-container .des-info-lists {
    line-height: 1.25rem;
    font-size: 1rem;
    margin-top: 1.5rem;
  }

  .left-img-container .des-container > h2 {
    line-height: 1.75rem;
    font-size: 2rem;
  }

  .left-img-container,
  .left-img-container {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .Plan-list-container .free-des {
    padding: 10px 5px;
  }

  .ant-btn.planSelectbtn {
    padding: 0.5rem;
  }

  .Plan-list-container .free-des {
    min-height: 120px;
  }

  .right-img-list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .agree-terms-wrap > div {
    margin-top: 0.5rem;
  }

  .privacy_wrap {
    padding: 1rem;
  }

  .left-img-container {
    padding-bottom: 2rem;
  }

  .progressbar-wrap ul {
    padding-left: 0;
  }

  .left-img-container,
  .left-img-container .des-container {
    margin-right: 0;
  }

  .right-header,
  .privacy_heading,
  .left-img-container .des-container > h2 {
    text-align: center;
  }

  .left-img-container .des-container > h2 span {
    margin-top: 1rem;
  }

  .success-submit-btn,
  .gray-submit-btn {
    margin: auto;
  }

  .pos-type .imgAlignCenter {
    margin: 0 10px;
  }
}

/* Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
  .store-epop-header,
  .store-epop-header1 {
    width: 100px;
    height: 100px;
    padding: 1rem 0.25rem;
  }

  .store-epop-header1 p,
  .store-epop-header p {
    margin-bottom: 3px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0px 3px;
  }

  .Right-wrapper {
    padding: 20px 10px;
  }

  .transections-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .pos-type .store-circle {
    width: 80px;
    height: 80px;
  }

  .planListWrap .Plan-list-container {
    min-height: 325px;
  }

  .Plan-list-container .free-des {
    min-height: 160px;
  }

  .plan-price .plan-price-type {
    font-size: 10px;
  }

  .plan-price .price-perMonth {
    font-size: 12px;
  }
}

/* Customized Media Queries (phones, less than 400px) */
@media (max-width: 400px) {
}

/* Extra small devices (portrait phones, less than 400px)*/
@media (max-width: 320px) {
}

/* Customized Media Queries (phones, less than 400px) */

/* /DESKTOP FIRST MEDIA QUERIES */

/**/

/* MOBILE FIRST MEDIA QUERIES */

/* Small devices (landscape phones, 576px and up)*/

@media (min-width: 400px) {
}

@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .right-img-list > div,
  .right-img-list > div p {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .right-img-list > div {
    width: 25%;
  }

  .right-img-list > div p {
    font-size: 0.9rem;
  }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
}

/* Customized Media Queries (Min Width) */

@media (min-width: 1024px) {
}

/* Customized Media Queries (Min Width) */

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
}

/* Extra extra large devices (1400px and up)*/
@media (min-width: 1400px) {
}

/* Extra extra extra large devices (4K and up)*/
@media (min-width: 2160px) {
}

/* /MOBILE FIRST MEDIA QUERIES */

/**/

/* MIXED SCREEN MEDIA QUERIES */

@media (min-width: 320px) and (max-width: 480px) {
}

@media (min-width: 320px) and (max-width: 575.98px) {
}

@media (min-width: 320px) and (max-width: 767.98px) {
}

@media (min-width: 320px) and (max-width: 767.98px) {
}

@media (min-width: 320px) and (max-width: 991.98px) {
}

@media (min-width: 320px) and (max-width: 1023.98px) {
}

@media (min-width: 320px) and (max-width: 1199.98px) {
}

@media (min-width: 320px) and (max-width: 1399.98px) {
}

@media (min-width: 480px) and (max-width: 575.98px) {
}

@media (min-width: 480px) and (max-width: 767.98px) {
}

@media (min-width: 480px) and (max-width: 991.98px) {
}

@media (min-width: 480px) and (max-width: 1023.98px) {
}

@media (min-width: 480px) and (max-width: 1199.98px) {
}

@media (min-width: 480px) and (max-width: 1399.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 576px) and (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 1023.98px) {
}

@media (min-width: 576px) and (max-width: 1199.98px) {
}

@media (min-width: 576px) and (max-width: 1399.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 768px) and (max-width: 1023.98px) {
}

@media (min-width: 768px) and (max-width: 1199.98px) {
}

@media (min-width: 768px) and (max-width: 1399.98px) {
}

@media (min-width: 992px) and (max-width: 1024.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .pos-type .store-circle {
    width: 115px;
    height: 115px;
  }
  .progressbar-wrap {
    padding-right: 0px !important;
  }
  .progressbar-wrap .signUp-progressBar {
    padding-left: 0px !important;
  }
}

@media (min-width: 992px) and (max-width: 1399.98px) {
}

@media (min-width: 1024px) and (max-width: 1199.98px) {
}

@media (min-width: 1024px) and (max-width: 1399.98px) {
}

/* /MIXED SCREEN MEDIA QUERIES */

/**/

/* COMBINED MEDIA QUERIES */

@media (min-width: 576px), (min-width: 768px), (min-width: 992px), (min-width: 1024px), (min-width: 1200px) {
}

/* /COMBINED MEDIA QUERIES */

.full-content {
  height: 100vh;
  overflow: hidden;
}

.left-side-content {
  overflow: auto;
  height: 100%;
}

.right-side-content {
  overflow: auto;
  height: 100%;
}

/* Modals */
.epop-modal .ant-modal-header {
  background: #f7f7f7;
  padding-top: 30px;
  padding-bottom: 10px;
  border-radius: 10px;
}
.epop-modal .ant-modal-body {
  padding-top: 0px;
}
.epop-modal .ant-modal-content {
  background: #f7f7f7;
  width: 700px;
  border-radius: 10px;
}
.antd-img-crop-modal .ant-modal-content {
  background: #f7f7f7;
  padding-bottom: 10px;
  border-radius: 10px;
}
.antd-img-crop-modal .ant-modal-header {
  padding-top: 20px;
  background: #f7f7f7;
  font-family: muli-bold;
  font-size: 1.125rem;
  color: #1f202b;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.antd-img-crop-modal .ant-modal-footer .ant-btn-primary {
  background-color: #4da59e !important;
  color: #fff !important;
}

/* Store Dashboard Icon section */
.store-logo-wrapper {
  height: 90px;
  width: 90px;
  background-color: '#C5C5C5';
  border-radius: 16px;
  box-shadow: '0px 8px 20px #00000029';
}

.store-logo-img {
  height: 100%;
  width: 100%;
  border-radius: 16px;
}

.store-logo-default-img {
  height: 100%;
  width: 100%;
  padding: 10px;
}

/* Store Dashboard Allowance Section */
.store-dashboard-allowance {
  display: flex;
  justify-content: center;
  align-items: center;
}

.store-dashboard-allowance p {
  font-size: 30px;
  line-height: 1;
  color: #ffffff;
  padding: 4px;
  margin-left: 5px;
  position: absolute;
  font-family: 'muli-bold';
}

/* e.pop community impact text */
.community-impact-logo {
  width: 50px;
  height: 50px;
}
.community-impact-title {
  font-size: 16px;
  font-family: 'muli-bold';
  color: #000;
  line-height: 20.5px;
}
.community-impact-hashtag {
  font-family: 'Quicksand-Regular';
  font-size: 16px;
  color: #818288;
  letter-spacing: 1px;
}
.community-impact-partners-logo {
  height: 60px !important;
}
.community-impact-subtext {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  font-family: 'muli-Regular';
  color: #000;
  line-height: 16px;
}
.community-impact-subtext-bold {
  font-size: 14px;
  font-family: 'muli-bold';
  color: #000 !important;
}
.community-impact-arrow {
  width: 30px;
  height: 30px;
  color: white;
  padding: 0 2px;
  padding-top: 3px;
  margin: 0 10px;
  font-size: 1.3rem;
  background: #7bbf44;
  border-radius: 0.25rem;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.community-impact-arrow:hover {
  background: #4da59e;
}
.community-impact-arrow > a {
  color: white !important;
}
/* receipt preference styles */
.receipt-border {
  border: 2px solid #319b93;
  border-radius: 6px;
  margin-right: 10px;
  height: auto;
  width: 93%;
  background-color: #fff;
}
.receipt-divider {
  background-color: #d6d7dc;
  border: #d6d7dc;
  height: 0.1rem;
  margin: 1rem 0rem;
}
.receipt-drop-down-border {
  border: #4da59e 0.15rem solid !important;
  border-radius: 8px !important;
}
.receipt-content-font-size {
  font-size: 11px;
}
.receipt-card-content {
  font-size: 11px;
  font-family: SourceSansPro-Regular;
}
.receipt-item-content {
  font-size: 11px;
  font-family: 'muli-Regular';
}
.receipt-form-contents {
  font-family: 'muli-Regular';
  font-size: 11px;
}
.receipt-form-contentsBold {
  font-family: 'muli-Bold';
  font-size: 11px;
}
.receipt-form-detail {
  font-family: SourceSansPro-Regular;
  font-size: 11px;
}
.receipt-logo {
  height: 70px;
  width: 70px;
}
.receipt-contact-detail {
  padding: 3px;
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 15px;
}
.receipt-checkbox-text {
  padding-left: 0px;
}
.receipt-contact-detail-check {
  display: contents;
}
.receipt-return-policy {
  justify-content: space-between;
  display: flex;
}
.optional-message {
  font-family: SourceSansPro-Regular !important;
  font-size: 11px !important;
}
.powered-by-stripe {
  width: 120px;
}
.receipt-contact-detail-info {
  white-space: pre-wrap;
  display: block;
  word-break: break-all;
  text-align: center;
}
.receipt-card-issuer {
  width: 34px;
}
